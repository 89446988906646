/*
  Theme Name: CreTech - IT Solutions & Technology React Template
  Theme URL: https://mediacity.co.in/cretech/
  Author: Mediacity
  Author URI: https://themeforest.net/user/media-city
  Creation Date: 06 September 2022
  Description: A default stylesheet for CreTech - IT Solutions & Technology React Template.
  Version: 1.1
*/

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
* Variable
* Animations
* Common
* Main Menu
* Pricing
* Work Process
* Mobile Drawer
* Page Header
* Funfact
* Page Header
* Contact
* Google Map
* Footer
* Blog
* Project
* Search Popup
* Services
* Team
* Client Carousel
* About
* CTA
* Testimonials
--------------------------------------------------------------*/

/***********************
* Variable
***********************/
@import 'variables';
@import 'mixins';
/***********************
* Animations
***********************/
@import 'animations';
/***********************
* Common
***********************/
@import 'common';
/***********************
* Main Menu
***********************/
@import 'main-menu';
/***********************
* Pricing
***********************/
@import 'pricing';
/***********************
* Work Process
***********************/
@import 'work-process';
/***********************
* Mobile Drawer
***********************/
@import 'mobile-drawer';
/***********************
* Page Header
***********************/
@import 'slider';
/***********************
* Funfact
***********************/
@import 'funfact';
/***********************
* Page Header
***********************/
@import 'page-header';
/***********************
* Contact
***********************/
@import 'contact';
/***********************
* Google Map
***********************/
@import 'google-map';
/***********************
* Footer
***********************/
@import 'footer';
/***********************
* Blog
***********************/
@import 'blog';
/***********************
* Project
***********************/
@import 'project';
/***********************
* Search Popup
***********************/
@import 'search-popup';
/***********************
* Services
***********************/
@import 'services';
/***********************
* Team
***********************/
@import 'team';
/***********************
* Client Carousel
***********************/
@import 'client-carousel';
/***********************
* About
***********************/
@import 'about';
/***********************
* CTA
***********************/
@import 'cta';
/***********************
* Testimonials
***********************/
@import 'testimonials';